.main-call {
    padding: 40px 0 0 0;
}

.offers {
    &__item {
        padding: 8px;
    }
}

.premium {
    padding: 16px 1.4rem;
    border-radius: 4px;

    & > p {
        margin: 5px 0;
    }
}

.offer {
    padding: 16px 24px 24px 24px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
    height: 100%;

    &__action {
        display: flex;
        justify-content: center;
    }

    .btn {
        background: #0d1b48;
        border-color: #0d1b48;
        width: 100%;
    }
}

.price {
    display: flex;
    justify-content: space-between;

    &__real {
        display: flex;
    }
}

.discount {
    display: flex;
    justify-content: flex-end;
}

.icon-check {
    color: #208163;
    margin-right: 8px;
}

.footer {
    background: #fff;
    border-top: 1px solid #eee;
}

.bestprice {
    & > .first {
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 37px;
        color: rgb(33, 37, 41);
    }
    & > .profit {
        margin-bottom: 10px;
        & > .old {
            font-size: 1.75rem;
            font-weight: 700;
            text-decoration: line-through;
            color: rgb(33, 37, 41);
        }

        & > .new {
            font-size: 1.75rem;
            font-weight: 700;
            margin-left: 10px;
            color: #02ae38;
        }
    }
    & > .hidden {
        visibility: hidden;
    }
    & > .month-price {
        color: rgb(108, 117, 125);
        font-weight: 500;
        font-size: 18px;
    }
}
