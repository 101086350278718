body {
    height: 100%;
}

#root {
    height: 100%;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    //font-family: Poppins,sans-serif;
    font-weight: 500;
}

.header {
    display: flex;
    align-items: center;

    &__logo {
        margin-right: 16px;
    }
}

.logo {
    width: 32px;
    height: 32px;
    background-color: #E5BF1E;
    background-image: url('logo-150x150.png');
    background-position: 50% 50%;
    background-size: 32px 32px;
    border-radius: 16px;
}

.company-title {
    font-weight: 600;
}
